/**
 * @file
 *
 * Form Input component. Used in Forms to render input element.
 * Example: <Input name="mdvip-id" label="MDVIP ID" type="text"/>
 */
import React, {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  FormEvent,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react'
import CurrencyInput from 'react-currency-input-field'

import { makeSafeForCSS } from '../../Constants'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
  type: string
  onBlur?: FocusEventHandler | undefined
  value?: string
  className?: string
  required?: boolean | undefined
  onChange?: ChangeEventHandler | undefined
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined
  pattern?: string | undefined
  maxLength?: number | undefined
  defaultValue?: string | number | undefined
  requiredMessage?: string | undefined
}

const InputPayment: FC<InputProps> = (props) => {
  const { name, label, type, onBlur, value, defaultValue, className, required, onChange, requiredMessage } = props

  const [message, setError] = useState('')

  useEffect(() => {
    // requiredMessage updated
    if (requiredMessage) {
      const input = document.querySelector(`input[name="${name}"]`)
      if (input) {
        ;(input as HTMLInputElement).setCustomValidity(requiredMessage)
        setError(requiredMessage)
      }
    }
  }, [requiredMessage])

  const errorCssClass = makeSafeForCSS(name)

  const displayMessage = (value: string) => {
    if (required) {
      const messageContainer = document.getElementsByClassName(errorCssClass).item(0)
      if (messageContainer !== null) {
        if (value.length == 0) {
          setError(`${label} field is required`)
        }
      }
    }
  }

  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    e.currentTarget.className = ''
    if (onBlur !== undefined) {
      onBlur(e)
    }

    if (e.currentTarget.value !== '' || e.target.value !== '') {
      e.currentTarget.className = className !== undefined ? className + ' js-filled' : 'js-filled'
    }

    displayMessage(e.currentTarget.value)
  }

  const cssClass = className !== undefined ? 'form-' + type + ' ' + className : 'form-' + type

  const onInvalid = (event: FormEvent<HTMLInputElement>) => {
    if (required) {
      ;(event.target as HTMLInputElement).setCustomValidity(message)
      displayMessage(event.currentTarget.value)
    }
  }
  const onInput = (event: FormEvent<HTMLInputElement>) => {
    // if the value is 8 digits then do not allow the user to enter more.
    if (event.currentTarget.value.length >= 8) {
      event.currentTarget.value = event.currentTarget.value.slice(0, 9)
    }

    ;(event.target as HTMLInputElement).setCustomValidity('')
    setError('')
  }

  return (
    <div className="text-field--wrapper">
      <div className={`inline-error ${errorCssClass}`}>{message}</div>
      <div className="elements-wrapper">
        {/* Toggle js-filled class on click and blur to animate the label up and down. */}
        <CurrencyInput
          className={cssClass}
          name={name}
          id={name}
          prefix="$"
          placeholder=""
          defaultValue={defaultValue}
          value={value}
          groupSeparator=","
          decimalSeparator="."
          allowNegativeValue={false}
          decimalsLimit={2}
          decimalScale={2}
          onBlur={onBlurHandler}
          onChange={onChange}
          required={required}
          onInput={onInput}
          onInvalid={onInvalid}
        />
        <label className="required" htmlFor={name}>
          {label}
        </label>
      </div>
    </div>
  )
}

export default InputPayment
