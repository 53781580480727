import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import React, { useState } from 'react'

interface InfoOutline {
  text?: string
}

const InfoOutline: React.FC<InfoOutline> = ({ text }) => {
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(!open)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        title={text || ''}
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <IconButton onClick={handleTooltipOpen} size="small" disableRipple>
          <InfoOutlinedIcon fontSize="small" className="color-brand" />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  )
}

export default InfoOutline
