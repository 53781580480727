import React from 'react'

import { MDVIP_PHONE, MDVIP_PHONE_HREF } from '../Constants'

const Footer: React.FC = () => {
  const currentDate = new Date().getFullYear()

  return (
    <footer className="swa-footer container-fluid" role="contentinfo">
      <div className="swa-footer-one">
        <div className="inner-footer container">
          <div className="region region-footer-one">
            <section className="clearfix">
              <div className="col-sm-6">
                <h2 className="lead-in">Need Help?</h2>
                <div className="global-element global-search-sectn">
                  <span className="global-contact-no fa fa-phone">
                    <a className="tel" href={MDVIP_PHONE_HREF} target="_parent">
                      {MDVIP_PHONE}
                    </a>
                  </span>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className="swa-footer-two">
        <div className="inner-footer container">
          <nav role="navigation" className="swa-footer-menu">
            <ul className="menu menu--utility-links nav">
              <li className="first">
                <a href="https://www.mdvip.com/privacy-policy" rel="noreferrer noopener" target="_blank">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="https://www.mdvip.com/terms-of-use" rel="noreferrer noopener" target="_blank">
                  Terms of Use
                </a>
              </li>
            </ul>
          </nav>
          <p>Copyright © {currentDate} MDVIP, All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
