import cloneDeep from 'lodash/cloneDeep'
import isArray from 'lodash/isArray'
import hash from 'object-hash'

/**
 * Redact the object
 * *NOTE* inclusionKeys should be the list of lower-case strings
 *
 * @param obj Any Input/Log object
 * @param inclusionKeys List of object lower-cased keys to include into the logs. (Lower-cased keys list)
 * @returns redacted object
 */
export const redactObject = (obj: any, inclusionKeys: string[], shouldCloneDeep = true): any => {
  if (!obj) {
    return obj
  }
  const variables = shouldCloneDeep ? cloneDeep(obj) : obj
  if (isArray(variables)) {
    variables.forEach((variable, index) => {
      variables[index] = redactObject(variable, inclusionKeys, false)
    })
  } else if (typeof variables === 'object') {
    Object.keys(variables).forEach((key) => {
      if (!variables[key]) {
        return
      }
      if (variables[key] && typeof variables[key] === 'object') {
        redactObject(variables[key], inclusionKeys, false)
        return
      }
      if (inclusionKeys.includes(key.toLowerCase())) return

      variables[key] = `redacted-${hash.sha1(variables[key])}`
    })
  }

  return variables
}
