/**
 * @file
 *
 * Form Input Radio component. Used in Forms to render input Radio element.
 * Example:
 * <Radio id="hsa" label="Yes" name="hsa"/>
 * <Radio id="hsa" label="No" name="hsa"/>
 */
import React, { ChangeEventHandler, FC, InputHTMLAttributes } from 'react'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string
  label: string
  name: string
  checked?: boolean | undefined
  onChange?: ChangeEventHandler | undefined
  required?: boolean | undefined
  defaultValue?: string | number | ReadonlyArray<string> | undefined
}

const Radio: FC<InputProps> = ({ name, label, id, checked, onChange, required, defaultValue }) => {
  return (
    <div className="text-radio--wrapper">
      <label htmlFor={id} className="form-control">
        {label}
        <input
          id={id}
          name={name}
          type="radio"
          className="form-radio"
          required={required}
          onChange={onChange}
          checked={checked}
          defaultValue={defaultValue}
        />
      </label>
    </div>
  )
}

export default Radio
