import axios from 'axios'

import { AUTHENTICATE_API_BASE_URL, RECAPTCHA_CLIENT_KEY } from '../config/config'

export type AuthenticateByMdvipIdParams = {
  mdvipId: string
  dob: string
}

export type AuthenticateByInvoiceIdParams = {
  invoiceId: string
  dob: string
}

export type AuthenticateByPhoneZipCodeParams = {
  phone: string
  zipCode: string
  dob: string
}

export type AuthenticateByZipCodeParams = {
  zipCode: string
  dob: string
}

export type AuthenticateByPhoneParams = {
  phone: string
  dob: string
}

export type AuthenticateParams = (
  | AuthenticateByMdvipIdParams
  | AuthenticateByInvoiceIdParams
  | AuthenticateByPhoneZipCodeParams
  | AuthenticateByZipCodeParams
  | AuthenticateByPhoneParams
) & {
  recaptchaToken: string | null
  onAuthenticateError: (errorMessage: string | null) => void
}

export type AuthenticateResponse = {
  token: string
  member: {
    id: string
    balance: number
    mdvipId: string
    estimatedPaidThrough: string
  }
}

export const authenticate = async (params: AuthenticateParams): Promise<AuthenticateResponse> => {
  const { recaptchaToken, onAuthenticateError, ...payload } = params
  try {
    const { data } = await axios.post<AuthenticateResponse>(`${AUTHENTICATE_API_BASE_URL}/api/authenticate`, payload, {
      headers: {
        ...(RECAPTCHA_CLIENT_KEY ? { Authorization: recaptchaToken } : {}),
      },
    })
    return data
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      if ('message' in (error.response?.data as any)) {
        throw new Error((error.response?.data as any).message)
      }
      throw new Error(error.message)
    }
    throw new Error('Something is wrong. Please contact the system administrator.')
  }
}
