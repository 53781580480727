/**
 * @file
 *
 * Verify by MDVIP ID and DOB form.
 *
 * example:
 * <IDForm/>
 */
import React, { FocusEvent, useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha-enterprise'
import { useSearchParams } from 'react-router-dom'

import { RECAPTCHA_CLIENT_KEY } from '../../config/config'
import { MDVIP_ACCOUNT_TIMEOUT_ERROR_MESSAGE, MDVIP_VERIFY_ACCOUNT_DEFAULT_ERROR_HTML } from '../../Constants'
import useAuthenticateContext from '../../hooks/useAuthenticate'
import { initLogRocket } from '../../utils/logrocket'
import { AuthFormError } from '../form-elements/AuthFormError'
import Input from '../form-elements/Input'
import InputDate from '../form-elements/InputDate'
import Submit from '../form-elements/Submit'
import LoadingSpinner from '../misc/Spinners/LoadingSpinner'

interface IDForm {
  mdvipId: string
  dob: string
}

const IDForm: React.FC = () => {
  const { errorMessage, loading, setParams } = useAuthenticateContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const expired = searchParams.get('expired') === 'true'

  const initialValues = { mdvipId: '', dob: '' }
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState(initialValues)
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)

  const [message, setMessage] = React.useState<string>('')
  const captchaRef = React.createRef<typeof ReCAPTCHA>()

  const isFormComplete =
    Object.values(formValues).every((val) => !!val) &&
    Object.values(formErrors).every((error) => !error) &&
    (!RECAPTCHA_CLIENT_KEY || recaptchaToken) &&
    !expired &&
    !message

  const resetRecaptcha = () => {
    captchaRef.current?.reset()
  }
  const resetForm = () => {
    setFormValues(initialValues)
    resetRecaptcha()
  }

  const validate = (values: IDForm): boolean => {
    const errors: IDForm = { mdvipId: '', dob: '' }
    if (values.mdvipId.length < 5) {
      errors.mdvipId = 'Please enter a valid MDVIP ID.'
    }

    setFormErrors(errors)
    return !errors.dob && !errors.mdvipId
  }

  const handleOnChange = (e: any) => {
    setSearchParams({})
    setMessage('')
    setFormErrors(initialValues)
    const { name, value } = e.currentTarget
    // if value is non-numeric, do not set the state
    if (name === 'mdvipId' && !/^\d*$/.test(value)) return

    setFormValues({ ...formValues, [name]: value })
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    // Get Values from the form.
    const target = e.target as typeof e.target & {
      mdvipId: { value: string }
      dob: { value: string }
    }
    const mdvipId = target.mdvipId.value
    const dob = target.dob.value

    await initLogRocket('mdvipId', mdvipId)

    if (!validate({ mdvipId, dob })) {
      return
    }

    // Send values to API.
    setParams({
      mdvipId,
      dob,
      recaptchaToken,
      onAuthenticateError: () => {
        setMessage(MDVIP_VERIFY_ACCOUNT_DEFAULT_ERROR_HTML)
        const errorMessage = document.getElementById('error-message')
        if (errorMessage) {
          errorMessage.classList.remove('hidden')
        }

        resetForm()
      },
    })
    setMessage('')
    resetRecaptcha()
  }

  useEffect(() => {
    if (expired && !errorMessage) {
      setMessage(MDVIP_ACCOUNT_TIMEOUT_ERROR_MESSAGE)
      const errorMessage = document.getElementById('error-message')
      if (errorMessage) {
        errorMessage.classList.remove('hidden')
      }
    }
  }, [expired, errorMessage])

  const validateId = (e: FocusEvent<HTMLInputElement>) => {
    const mdvipId = e.currentTarget.value
    setFormErrors({
      ...formErrors,
      mdvipId: '',
    })
    let errorMessage = ''
    if (mdvipId.length < 5) {
      errorMessage = 'Please enter a valid MDVIP ID.'
      setFormErrors({
        ...formErrors,
        mdvipId: errorMessage,
      })
    }
  }

  return (
    <React.Fragment>
      {loading && <LoadingSpinner loading={loading} />}
      <form onSubmit={onSubmit}>
        <Input
          name="mdvipId"
          privateData={true}
          value={formValues.mdvipId}
          label="MDVIP ID"
          className={formErrors.mdvipId ? 'element-error js-filled' : ''}
          required={true}
          type="text"
          onChange={handleOnChange}
          maxLength={16}
          onBlur={validateId}
          requiredMessage={formErrors.mdvipId}
        />
        <InputDate
          name="dob"
          privateData={true}
          value={formValues.dob}
          className={formErrors.dob ? 'form-date element-error js-filled' : 'form-date'}
          required={true}
          label="Date of Birth (MM/DD/YYYY)"
          onChange={handleOnChange}
          requiredMessage="Please enter a valid Date of Birth."
        />
        <div
          className={['error', expired ? '' : 'hidden'].join(' ')}
          id="error-message"
          dangerouslySetInnerHTML={{
            __html: expired ? MDVIP_ACCOUNT_TIMEOUT_ERROR_MESSAGE : MDVIP_VERIFY_ACCOUNT_DEFAULT_ERROR_HTML,
          }}
        />
        {RECAPTCHA_CLIENT_KEY && (
          <ReCAPTCHA ref={captchaRef} sitekey={RECAPTCHA_CLIENT_KEY} onChange={setRecaptchaToken} />
        )}
        <Submit id="mdvip-id-submit" value="Verify Account" disabled={!isFormComplete} />
        <AuthFormError message={message} />
      </form>
    </React.Fragment>
  )
}

export default IDForm
