/**
 * @file
 *
 * Form Input component. Used in Forms to render input element.
 * Example: <Input name="mdvip-id" label="MDVIP ID" type="text"/>
 */
import React, { ChangeEventHandler, FC, FocusEventHandler, InputHTMLAttributes, useEffect, useState } from 'react'
import Select from 'react-select'
import { OptionsOrGroups, PropsValue } from 'react-select/dist/declarations/src/types'

import Option, { makeSafeForCSS } from '../../Constants'

interface DropDownProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
  onBlur?: FocusEventHandler | undefined
  selectValue?: PropsValue<Option> | undefined
  options: OptionsOrGroups<any, any>
  className?: string
  required?: boolean | undefined
  onChange?: ChangeEventHandler | undefined
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined
  pattern?: string | undefined
  maxLength?: number | undefined
  min?: number | string | undefined
  step?: number | string | undefined
  requiredMessage?: string | undefined
  privateData?: boolean
}

const DropDown: FC<DropDownProps> = (props) => {
  const { name, label, onBlur, selectValue, className, required, onChange, options, requiredMessage, privateData } =
    props

  const [message, setError] = useState('')

  const errorCssClass = makeSafeForCSS(name)

  useEffect(() => {
    const input = document.querySelector(`input[name="${name}"]`)
    // requiredMessage updated
    if (input) {
      if (requiredMessage) {
        ;(input as HTMLInputElement).setCustomValidity(requiredMessage)
        setError(requiredMessage)
      } else {
        ;(input as HTMLInputElement).setCustomValidity('')
        setError('')
      }
    }
  }, [requiredMessage])

  const displayMessage = (value: string) => {
    if (required) {
      const messageContainer = document.getElementsByClassName(errorCssClass).item(0)
      if (messageContainer !== null) {
        if (value.length == 0) {
          if (typeof requiredMessage == 'string' && requiredMessage.length > 0) {
            setError(requiredMessage)
          }
        } else {
          setError('')
        }
      }
    }
  }

  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur !== undefined) {
      onBlur(e)
    }
    const $element = document.getElementsByName(name).item(0) as HTMLInputElement
    displayMessage($element.value)
  }
  const cssClass = className !== undefined ? 'select-field--wrapper ' + className : 'select-field--wrapper'

  const isPrivate = privateData && { 'data-private': 'lipsum' }
  return (
    <div className="select-field--wrapper">
      <div className={`inline-error ${errorCssClass}`}>{message}</div>

      <div
        className="elements-wrapper"
        /* eslint-disable react/jsx-props-no-spreading */
        {...isPrivate}
      >
        <Select
          name={name}
          required={required}
          onBlur={onBlurHandler}
          className={cssClass}
          value={selectValue}
          onChange={onChange}
          classNamePrefix="select"
          placeholder={label}
          options={options}
        />
      </div>
    </div>
  )
}

export default DropDown
