import '../../../scss/_loading-spinner.scss'

import React, { useEffect, useState } from 'react'

type Props = {
  loading: boolean
}

const LoadingSpinner: React.FC<Props> = ({ loading }) => {
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(loading)
    // set 1 minute timer to stop loading spinner on error
    const timer = setTimeout(() => {
      setLoading(false)
    }, 60000)
    return () => clearTimeout(timer)
  }, [loading])

  return isLoading ? (
    <div className="spinner-overlay">
      <div className="loading-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : null
}

export default LoadingSpinner
