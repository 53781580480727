import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { useEffect, useState } from 'react'

import { MIDDLEWARE_GRAPHQL_URL } from '../config/config'

const getApolloClient = (token: string): ApolloClient<NormalizedCacheObject> => {
  const httpLink = createHttpLink({
    uri: MIDDLEWARE_GRAPHQL_URL,
    fetch: (input: RequestInfo | URL, init?: RequestInit | undefined) => {
      return window.fetch(input, init)
    },
  })

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        contentType: 'application/json',
        authorization: token || '',
        channel: 'Express Pay',
        entityId: 'mdvip_connect_express_pay',
      },
    })
    return forward(operation)
  })
  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    name: 'mdvip_connect_express_pay',
    version: process.env.NODE_ENV === 'production' ? 'build' : 'develop',
  })
}

export const useApolloClient = (token: string | null): ApolloClient<NormalizedCacheObject> | null => {
  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null)

  useEffect(() => {
    if (token) {
      setApolloClient(getApolloClient(token))
    } else {
      setApolloClient(null)
    }
  }, [token])

  return apolloClient
}
