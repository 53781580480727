import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import React, { useState } from 'react'

import { ASSETS_URL } from '../../config/config'

interface RoutingToolTip {
  text?: string
}

const RoutingToolTip: React.FC<RoutingToolTip> = ({ text }) => {
  const [showToolTip, setShowToolTip] = useState(false)
  const handleClick = () => {
    setShowToolTip(!showToolTip)
  }

  return (
    <div>
      <div className="secure-info d-flex py-3 d-inline-flex align-items-center" onClick={handleClick}>
        <div>{text}</div>
        <InfoOutlinedIcon fontSize="small" className="color-brand" />
      </div>
      {showToolTip && (
        <div className="secure-info d-flex py-3">
          <img className="img-fluid" src={`${ASSETS_URL}/assets/images/routing-account-check-image.png`} alt="check" />
        </div>
      )}
    </div>
  )
}

export default RoutingToolTip
