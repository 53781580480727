import Alert from '@mui/material/Alert'
import React, { ReactElement } from 'react'

interface ErrorTextProp {
  error: string
}

const ErrorText = ({ error }: ErrorTextProp): ReactElement => (
  <Alert className="form--field-error-message mb-5" severity="error" variant="outlined">
    {error}
  </Alert>
)

export default ErrorText
