import 'bootstrap/dist/css/bootstrap.min.css'
import './scss/styles.scss'

import React from 'react'

import Footer from './components/Footer'
import Header from './components/Header'
import Main from './components/Main'

function App() {
  return (
    <div>
      <Header />
      <div role="main" className="main-container container-fluid">
        <div className="container">
          <div className="row">
            <section className="col-12">
              <Main />
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default App
