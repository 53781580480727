import LogRocket from 'logrocket'
import { useEffect, useState } from 'react'
import { decodeToken } from 'react-jwt'

export const useJwtExpired = (onExpired: () => void, token: string | null) => {
  const [expireHandle, setExpireHandle] = useState<NodeJS.Timeout | null>(null)

  // Handle Token expiration
  useEffect(() => {
    if (!token) {
      return
    }
    const decoded = decodeToken<{ exp: number; iat: number }>(token)
    if (!decoded || typeof decoded === 'string') {
      return
    }

    if (!decoded.iat) {
      LogRocket.track('Token issued date', {
        tokenIssuedDate: decoded.iat,
      })
    }

    if (!decoded.exp) {
      LogRocket.track('Token expiration date', {
        tokenExpirationDate: decoded.exp,
      })
      return
    }
    if (expireHandle) {
      LogRocket.track('Token expired', {
        tokenExpirationDate: decoded.exp,
      })
      clearTimeout(expireHandle)
    }
    // Triggers expired handler 5 mins ahead before the actual token expires
    const newExpireHandle = setTimeout(() => {
      setExpireHandle(null)
      onExpired()
    }, Math.max((decoded.exp - 5 * 60) * 1000 - new Date().getTime(), 0))
    setExpireHandle(newExpireHandle)
  }, [token])

  useEffect(() => {
    return () => {
      if (expireHandle) {
        clearTimeout(expireHandle)
        setExpireHandle(null)
      }
    }
  }, [])
}
