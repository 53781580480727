/**
 * @file
 *
 * Form Input Submit component. Used in Forms to render input Submit element.
 * Example:
 * <Submit id="payment-review" value="Review"/>
 */
import React, { FC, InputHTMLAttributes } from 'react'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string
  value: string
  disabled?: boolean | undefined
}

const Submit: FC<InputProps> = ({ id, value, disabled }) => {
  return (
    <div className="submit-field--wrapper">
      <input id={id} value={value} disabled={disabled} className="btn-default" type="submit" />
    </div>
  )
}

export default Submit
