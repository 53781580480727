import React from 'react'

import { parseServerDateToLocalDateString } from '../../utils/dateUtils'

interface Balance {
  balance: number
  estimatedPaidThrough: string
}

const Balance: React.FC<Balance> = (props) => {
  const { balance, estimatedPaidThrough } = props
  const estimatedPaidThroughDateFormatted = parseServerDateToLocalDateString(estimatedPaidThrough)

  if (balance > 0) {
    return (
      <div className="table-row">
        <div className="table-column">
          <div className="label">Amount Due</div>
          <div className="info">
            $
            {balance
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </div>
        </div>
        <div className="table-column">
          <div className="label">Due Date</div>
          <div className="info">{estimatedPaidThroughDateFormatted}</div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="table-row">
        <div className="table-column">
          <div className="label">Estimated Paid Through Date</div>
          <div className="info">{estimatedPaidThroughDateFormatted}</div>
        </div>
      </div>
    )
  }
}

export default Balance
