import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded'
import React from 'react'

import { MDVIP_VERIFY_ACCOUNT_DEFAULT_ERROR_HTML } from '../../Constants'

interface Props {
  message?: string
}

export const AuthFormError: React.FC<Props> = ({ message = '' }) => {
  if (typeof message === undefined) {
    message = MDVIP_VERIFY_ACCOUNT_DEFAULT_ERROR_HTML
  }
  return (
    <div className={message ? 'desktop-only bottom-error' : 'desktop-only bottom-error hidden'}>
      <div>
        <PriorityHighRoundedIcon />
      </div>
      <div dangerouslySetInnerHTML={{ __html: message }} />
    </div>
  )
}
