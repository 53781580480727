import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import React, { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { ASSETS_URL } from '../config/config'
import { push_pageview } from '../service/analytics'

interface propState {
  transactionName: string
  transactionAmount: string
  paymentMethod: string
}

const ThankYou: React.FC = () => {
  useEffect(() => {
    push_pageview({ page_id: 'express_pay_thank_you', page_name: 'Thank You' })
    window.sessionStorage.setItem('session', '0')
    window.sessionStorage.removeItem('mdvip_ep_customer_data')
    // Clear react state.
    window.history.replaceState({}, document.title)
  }, [])

  const location = useLocation()
  if (location.state === null) {
    return <Navigate replace to="/" />
  }
  const { transactionName, transactionAmount, paymentMethod } = location.state as propState

  return (
    <>
      <div className="main-content thank-you">
        <div className="d-flex column-wrapper">
          <div className="icon">
            <CheckCircleIcon />
          </div>
          <h1>Thank you. Your payment has been successfully scheduled.</h1>
        </div>

        <div className="d-flex inner-content-wrapper">
          <div className="thank-you-content col">
            <div className="disclamer">
              Confirmation of scheduled payment will be sent to the member’s email address, if available. Payment will
              be processed in 1 – 2 days.
            </div>
            <div className="content payment-info">
              <div className="member-info">
                <div className="table-row">
                  <div className="table-column">
                    <div className="label">Confirmation #</div>
                    <div className="info">{transactionName}</div>
                  </div>
                </div>
                <div className="table-row">
                  <div className="table-column">
                    <div className="label">Amount Paid</div>
                    <div className="info">
                      $
                      {parseFloat(transactionAmount)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </div>
                  </div>
                </div>
                <div className="table-row">
                  <div className="table-column">
                    <div className="label">Payment Method</div>
                    <div className="info" data-private>
                      {paymentMethod}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="side-image col-lg-4">
            <img src={`${ASSETS_URL}/assets/images/infographic-image.svg`} alt="Infographic" />
          </div>
        </div>
      </div>
    </>
  )
}

export default ThankYou
