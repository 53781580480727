/**
 * Hash string input
 *
 * @param input unknown field string
 * @returns redacted sha-256 hex
 */
export async function sha256(input: string) {
  const msgBuffer = new TextEncoder().encode(input)
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)
  return hex(hashBuffer)
}

/**
 * Hex utility function
 *
 * @param buffer unknown field Array
 * @returns sha-256 hex
 */
function hex(buffer: ArrayBuffer) {
  const hexCodes = []
  const view = new DataView(buffer)
  for (let i = 0; i < view.byteLength; i += 4) {
    hexCodes.push(('00000000' + view.getUint32(i).toString(16)).slice(-8))
  }
  return hexCodes.join('')
}
