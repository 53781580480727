import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import React from 'react'

import { ASSETS_URL } from '../config/config'
import { MDVIP_LOGIN_LINK, MDVIP_PHONE, MDVIP_PHONE_HREF } from '../Constants'

const Header: React.FC = () => {
  return (
    <header>
      <div className="swa-main-nav-wrapper">
        <div className="header-container container">
          <div className="row">
            <div className="region region-header">
              <section className="block d-flex justify-content-between align-items-center w-100 header">
                <div className="swa-logo col-3">
                  <a href="https://www.mdvip.com" target="_blank" title="Link to MDVIP homepage" rel="noreferrer">
                    <img className="img-responsive" src={`${ASSETS_URL}/logo.png`} alt="MDVIP logo" />
                  </a>
                </div>
                <div className="mobile-header">Express Pay</div>
                <div className="col-2 phone-login d-flex align-items-center justify-content-between mt-1 mt-lg-0">
                  <div className="phone-wrapper text-white">
                    <a href={MDVIP_PHONE_HREF} rel="noopener noreferrer" className="text-white">
                      <LocalPhoneIcon fontSize="small" />
                      {MDVIP_PHONE}
                    </a>
                  </div>
                  <div className="login-wrapper text-white">
                    <a
                      href={MDVIP_LOGIN_LINK}
                      target="_blank"
                      className="log-in text-white text-uppercase"
                      rel="noreferrer noopener"
                    >
                      <LockOutlinedIcon fontSize="small" />
                      Login
                    </a>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
