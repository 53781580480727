import { format, formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz'

const MDVIP_SALESFORCE_TIMEZONE = 'America/New_York'

/**
 * Formats the date string
 * i.e. Date of Birth comes as: 1950-10-29
 * Returns 10/29/1950
 *
 * @param date Date string in `yyyy-MM-dd` format
 * @returns Date string in `MM/dd/yyyy` format
 *
 * NOTE: This function never performs any timezone transformation.
 */
export const formatDateString = (dateStr: string | Date): string => {
  if (typeof dateStr === 'string') {
    const [year, month, date] = dateStr.split('-')
    return `${month}/${date}/${year}`
  } else {
    return format(dateStr, 'MM/dd/yyyy')
  }
}

/**
 * Formats the date string to server format
 * i.e. Date of Birth comes as: 10/29/1950
 * Returns 1950-10-29
 *
 * @param date Date string in `MM/dd/yyyy` format
 * @returns Date string in `yyyy-MM-dd` format
 *
 * NOTE: This function never performs any timezone transformation.
 */
export const formatServerDateString = (dateStr: string | Date): string => {
  if (typeof dateStr === 'string') {
    const [month, date, year] = dateStr.split('/')
    return `${year}-${month}-${date}`
  } else {
    return format(dateStr, 'yyyy-MM-dd')
  }
}

/**
 * Formats the date string from the server
 * i.e. Estimated paid through comes as: 2023-03-30 (Eastern time)
 * Returns:
 *  - 30/03/2023 - if browser timezone is Eastern time
 *  - 29/03/2023 - if browser timezone is Pacific time
 *
 * @param date Server date string in `yyyy-MM-dd` format
 *
 * @returns Date string in `MM/dd/yyyy` format in local timezone
 */
export const parseServerDateToLocalDateString = (date: string): string => {
  const utcDate = zonedTimeToUtc(`${date} 00:00:00`, MDVIP_SALESFORCE_TIMEZONE)
  return format(utcDate, 'MM/dd/yyy')
}

/**
 * Formats the local date string in the server-side date format
 * i.e 29/03/2023 and browser timezone is Pacific time
 * Returns:
 *  - 2023-03-30 - Eastern time
 *
 * @param date Local date string or Date object
 *
 * @returns Date string in 'yyyy-MM-dd` format in server timezone
 */
export const parseLocalDateToServerDateString = (date: Date): string => {
  return formatInTimeZone(date, MDVIP_SALESFORCE_TIMEZONE, 'yyyy-MM-dd')
}
