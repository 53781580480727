import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { BROWSER_ROUTER_BASENAME } from '../config/config'
import { MDVIP_SLOGAN } from '../Constants'
import { AuthenticateProvider } from '../hooks/useAuthenticate'
import PaymentInfo from './PaymentInfo'
import Review from './Review'
import ThankYou from './ThankYou'
import VerifyAccount from './VerifyAccount'

const Main: React.FC = () => {
  return (
    <BrowserRouter basename={BROWSER_ROUTER_BASENAME}>
      <AuthenticateProvider>
        <div className="page-verify-account form-content">
          <div className="header">
            <h2>Express Pay</h2>
            <div className="slogan">{MDVIP_SLOGAN}</div>
          </div>
          <Routes>
            <Route path="/" element={<VerifyAccount />} />
            <Route path="/payment" element={<PaymentInfo />} />
            <Route path="/review" element={<Review />} />
            <Route path="/thank-you" element={<ThankYou />} />
          </Routes>
        </div>
      </AuthenticateProvider>
    </BrowserRouter>
  )
}

export default Main
