import React from 'react'

interface NavProps {
  activeIndex: number
}

const Navigation: React.FC<NavProps> = (props): JSX.Element => {
  return (
    <div className="pagination-navigation">
      <ul>
        <li className={`${props.activeIndex === 0 ? 'active' : 'not-active'}`}>
          <span>1</span>
          <span className={`${props.activeIndex === 0 ? 'active' : 'not-active'} desktop-tablet`}>Verify Account</span>
          <span className={`${props.activeIndex === 0 ? 'active' : 'not-active'} mobile-tablet`}>Account</span>
        </li>
        <li className={`${props.activeIndex === 1 ? 'active' : 'not-active'}`}>
          <span>2</span>
          <span className={`${props.activeIndex === 1 ? 'active' : 'not-active'} desktop-tablet`}>
            Add Payment Info
          </span>
          <span className={`${props.activeIndex === 1 ? 'active' : 'not-active'} mobile-tablet`}>Payment</span>
        </li>
        <li className={`${props.activeIndex === 2 ? 'active' : 'not-active'}`}>
          <span>3</span>
          <span className={`${props.activeIndex === 2 ? 'active' : 'not-active'} desktop-tablet`}>Submit Payment</span>
          <span className={`${props.activeIndex === 2 ? 'active' : 'not-active'} mobile-tablet`}>Submit Payment</span>
        </li>
      </ul>
    </div>
  )
}

export default Navigation
