/**
 * @file
 *
 * Review the information before payment form
 *
 * example:
 * <ReviewForm/>
 */
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { MDVIP_VERIFY_ACCOUNT_DEFAULT_ERROR_HTML } from '../../Constants'
import {
  CreateNewPaymentProfileInput,
  PaymentProfileAchAccountType,
  PaymentProfileCardType,
  PaymentProfileHsaCard,
  PaymentProfilePaymentType,
  PaymentType,
  useCreateNewPaymentProfileMutation,
  useCreateOneTimePaymentMutation,
} from '../../generated/hooks'
import { parseLocalDateToServerDateString } from '../../utils/dateUtils'
import InformationSecure from '../misc/InformationSecure'
import LoadingSpinner from '../misc/Spinners/LoadingSpinner'

export type ReviewFormPropState =
  | {
      amount: string
      ACHInfo: null
      CreditCardInfo: {
        PaymentType__c: PaymentProfilePaymentType
        gatewayTokenId: string
        authTransactionId: string
        CCLast4__c: string
        Name_on_Card__c: string
        Expiration_Month__c: string
        Expiration_Year__c: string
      }
      type: 'Card'
      paymentMethod: string
      Address: string
      Address2: string
      City: string
      State: string
      BillingZipCode: string
      scheduled: Date
      estimatedPaidThrough: string
      HSACard__c: PaymentProfileHsaCard
      contact_id: string
    }
  | {
      amount: string
      ACHInfo: {
        paymentType: PaymentProfilePaymentType
        authTransactionId: string
        gatewayTokenId: string
        ACHBankName__c: string
        ACHLast6__c: string
        ACHRoutingLast9__c: string
        ACH_Name_of_Account__c: PaymentProfileAchAccountType
        Name_on_Card__c: string
      }
      CreditCardInfo: null
      type: 'Bank'
      paymentMethod: string
      Address: string
      Address2: string
      City: string
      State: string
      BillingZipCode: string
      scheduled: Date
      estimatedPaidThrough: string
      HSACard__c: PaymentProfileHsaCard
      contact_id: string
    }

const ReviewForm: React.FC = () => {
  const location = useLocation()
  const {
    amount,
    ACHInfo,
    CreditCardInfo,
    type,
    paymentMethod,
    scheduled,
    Address,
    Address2,
    City,
    State,
    BillingZipCode,
    HSACard__c,
  } = location.state as ReviewFormPropState
  const [message, setMessage] = React.useState('')
  const [showLoader, setShowLoader] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const [createPaymentProfile] = useCreateNewPaymentProfileMutation({ fetchPolicy: 'no-cache' })
  const [createOneTimePayment] = useCreateOneTimePaymentMutation({ fetchPolicy: 'no-cache' })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate()

  /**
   * Payment information form submission.
   *
   * @param e
   *  Event.
   */
  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setDisabled(true)
    setShowLoader(true)
    let input: CreateNewPaymentProfileInput
    const mdvip_ep_customer_data = JSON.parse(window.sessionStorage.getItem('mdvip_ep_customer_data') || '{}')
    const Source_URL__c = mdvip_ep_customer_data.Source_URL__c || 'https://www.mdvip.com/pay?undefined'

    if (type === 'Card') {
      // Card Input.
      input = {
        ACHInfo: null,
        CreditCardInfo: {
          CCLast4__c: CreditCardInfo.CCLast4__c,
          Name_on_Card__c: CreditCardInfo.Name_on_Card__c,
          Card_Type__c: PaymentProfileCardType.Commercial,
          Expiration_Month__c: CreditCardInfo.Expiration_Month__c.toString(),
          Expiration_Year__c: '20' + CreditCardInfo.Expiration_Year__c,
          PaymentType__c: CreditCardInfo.PaymentType__c,
        },
        Active__c: true,
        HSACard__c: HSACard__c,
        Gateway_Token_Id__c: CreditCardInfo.gatewayTokenId,
        Auth_Transaction_Id__c: CreditCardInfo.authTransactionId,
        Source_URL__c: Source_URL__c,
        Web_MD_Domain__c: mdvip_ep_customer_data?.Web_MD_Domain__c,
        Affluence__c: null,
        Pre_Paid_Type__c: null,
        Address_1__c: Address,
        Address_2__c: Address2,
        City__c: City,
        States__c: State,
        Zipcode__c: BillingZipCode,
      }
    } else {
      // Bank Input
      input = {
        ACHInfo: {
          ACHBankName__c: ACHInfo.ACHBankName__c,
          ACHLast6__c: ACHInfo.ACHLast6__c,
          ACHRoutingLast9__c: ACHInfo.ACHRoutingLast9__c,
          ACH_Name_of_Account__c: ACHInfo.ACH_Name_of_Account__c,
          Name_on_Card__c: ACHInfo.Name_on_Card__c,
        },
        HSACard__c: HSACard__c,
        CreditCardInfo: null,
        Active__c: true,
        Gateway_Token_Id__c: ACHInfo.gatewayTokenId,
        Auth_Transaction_Id__c: ACHInfo.authTransactionId,
        Source_URL__c: Source_URL__c,
        Web_MD_Domain__c: mdvip_ep_customer_data?.Web_MD_Domain__c,
        Affluence__c: null,
        Pre_Paid_Type__c: null,
        Address_1__c: Address,
        Address_2__c: Address2,
        City__c: City,
        States__c: State,
        Zipcode__c: BillingZipCode,
      }
    }
    createPaymentProfile({
      variables: { input },
      onCompleted(data) {
        if (data.createNewPaymentProfile.__typename === 'CreateNewPaymentProfileSuccess') {
          const id = data.createNewPaymentProfile.Id
          createOneTimePayment({
            variables: {
              input: {
                Amount__c: parseFloat(amount),
                Payment_Profile__c: id,
                Schedule_Date__c: parseLocalDateToServerDateString(scheduled),
                Type__c: type === 'Card' ? PaymentType.CreditCardPayment : PaymentType.Payment,
                UTM_Campaign__c: mdvip_ep_customer_data?.UTM_Campaign__c,
                UTM_Medium__c: mdvip_ep_customer_data?.UTM_Medium__c,
                UTM_Source__c: mdvip_ep_customer_data?.UTM_Source__c,
                UTM_Term__c: mdvip_ep_customer_data?.UTM_Term__c,
                Source_URL__c: Source_URL__c,
                Web_MD_Domain__c: mdvip_ep_customer_data?.Web_MD_Domain__c,
              },
            },
            onCompleted(data) {
              if (data.createOneTimePayment.__typename === 'CreateOneTimePaymentSuccess') {
                navigate('/thank-you', {
                  state: {
                    transactionName: data.createOneTimePayment.Name,
                    transactionAmount: amount,
                    paymentMethod: paymentMethod,
                  },
                })
              } else if (data.createOneTimePayment.__typename === 'CreateOneTimePaymentFailure') {
                setShowLoader(false)
                setDisabled(false)
                setMessage(
                  data.createOneTimePayment.errors.length > 0
                    ? data.createOneTimePayment.errors[0].message
                    : MDVIP_VERIFY_ACCOUNT_DEFAULT_ERROR_HTML,
                )
                const errorMessage = document.getElementById('error-message')
                if (errorMessage) {
                  errorMessage.classList.remove('hidden')
                }
              }
            },
          })
        } else if (data.createNewPaymentProfile.__typename === 'CreateNewPaymentProfileFailure') {
          setShowLoader(false)
          setDisabled(false)
          setMessage(
            data.createNewPaymentProfile.errors.length > 0
              ? data.createNewPaymentProfile.errors[0].message
              : MDVIP_VERIFY_ACCOUNT_DEFAULT_ERROR_HTML,
          )
          const errorMessage = document.getElementById('error-message')
          if (errorMessage) {
            errorMessage.classList.remove('hidden')
          }
        } else {
          setShowLoader(false)
          setDisabled(false)
          const errorMessage = document.getElementById('error-message')
          if (errorMessage) {
            errorMessage.classList.remove('hidden')
          }
          setMessage(MDVIP_VERIFY_ACCOUNT_DEFAULT_ERROR_HTML)
        }
      },
      onError() {
        showLoader && setShowLoader(false)
        setDisabled(false)
        const errorMessage = document.getElementById('error-message')
        if (errorMessage) {
          errorMessage.classList.remove('hidden')
        }
        setMessage(MDVIP_VERIFY_ACCOUNT_DEFAULT_ERROR_HTML)
      },
    })
  }

  return (
    <React.Fragment>
      {!message && showLoader && <LoadingSpinner loading={showLoader} />}
      <form className="review-form" onSubmit={onSubmit}>
        <div
          className="error hidden"
          id="error-message"
          dangerouslySetInnerHTML={{ __html: MDVIP_VERIFY_ACCOUNT_DEFAULT_ERROR_HTML }}
        />
        <InformationSecure />
        <div className="submit-field--wrapper">
          <input
            id="payment-review"
            value={
              'Submit Payment $' +
              parseFloat(amount)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            disabled={disabled}
            className="btn-default"
            type="submit"
          />
        </div>
      </form>
    </React.Fragment>
  )
}

export default ReviewForm
