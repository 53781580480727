// Extend interface for global window object.
declare global {
  interface Window {
    utag: {
      view: any
    }
  }
}

// Page View Object to be submitted to Tealium.
export type PageViewObject = {
  page_name: string
  page_id: string
  tealium_event?: string
}

// Push page view event.
export const push_pageview = (pageViewEvent: PageViewObject) => {
  // Check if utag is on the global window object.
  if (typeof window.utag === 'object') {
    // Push the page view event.
    window.utag.view({
      ...pageViewEvent,
      tealium_event: 'page_view',
    })
  }
}
