import LockIcon from '@mui/icons-material/Lock'
import React from 'react'

import InfoOutline from './InfoOutline'

const InformationSecure: React.FC = () => {
  return (
    <div className="secure-info d-flex py-3">
      <LockIcon fontSize="small" />
      <span className="px-4">Your information is secure.</span>
      <InfoOutline text="MDVIP uses technology to securely encrypt the transmission of sensitive information." />
    </div>
  )
}

export default InformationSecure
